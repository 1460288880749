<template>
  <div class="user-accept-invite">
    <v-container class="content pb-5">
      <v-row>
        <v-col cols="12" md="5" class="hidden-sm-and-down">
          <v-img
            alt="img"
            class="img"
            src="@/assets/img/auth/img-1.jpg"
            max-width="600"
          />
        </v-col>

        <v-col cols="12" offset-md="1" md="6" align-self="center">
          <v-card color="pa-5 pa-md-8" rounded="xl">
            <h3 v-if="successfulUserAcceptInvite" class="heading-h3">
              Go to login
            </h3>
            <h3 v-else class="heading-h3">Accept Invite</h3>
            <v-form ref="form" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" class="d-flex flex-column">
                  <!-- First Name -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="firstName"
                    :rules="firstNameRules"
                    label="First Name"
                    outlined
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        First Name <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <!-- Last Name -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="lastName"
                    :rules="lastNameRules"
                    label="Last Name"
                    outlined
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Last Name <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <!-- Email -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    outlined
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Email <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <!-- First Phone Number -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="phone1"
                    :rules="phone1Rules"
                    label="First Phone Number"
                    type="tel"
                    outlined
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        First Phone Number
                        <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <!-- Second Phone Number -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="phone2"
                    :rules="phone2Rules"
                    label="Second Phone Number"
                    type="tel"
                    outlined
                  >
                    <template v-slot:label>
                      <p class="body-1">Second Phone Number</p>
                    </template>
                  </v-text-field>

                  <!-- Password -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    outlined
                    @click:append="showPassword = !showPassword"
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Password <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <!-- Confirm Password -->
                  <v-text-field
                    v-if="!successfulUserAcceptInvite"
                    v-model="confirmPassword"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="confirmPasswordRules"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    label="Confirm Password"
                    counter
                    outlined
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Confirm Password <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>

                  <v-btn
                    v-if="successfulUserAcceptInvite"
                    :to="{ name: 'Login' }"
                    class="primary mt-4"
                    rounded
                  >
                    Login
                  </v-btn>

                  <v-btn
                    v-else
                    @click="validate()"
                    class="primary mt-4"
                    rounded
                  >
                    Send
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="10">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "UserAcceptInvite",

  data: () => ({
    overlay: false,

    // redirect
    redirect: "/dashboard/inbox",

    token: "",

    successfulUserAcceptInvite: false,

    // first name
    firstName: "",
    firstNameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length >= 3 || "Can not be less than 3 characters",
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // last name
    lastName: "",
    lastNameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length >= 3 || "Can not be less than 3 characters",
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // email
    email: "",
    emailRules: [
      (v) => !!v || "Required",
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<script>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Invalid e-mail.";
      },
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // First Phone Number
    phone1: "",
    phone1Rules: [
      (v) => !!v || "Required",
      (v) => v.length <= 20 || "Can not be more than 20 characters",
      // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      // (v) => v.length == 12 || "Mobile phone is incorrect",
    ],

    // Second Phone Number
    phone2: "",
    phone2Rules: [
      // (v) => !!v || "Required",
      // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      // (v) => v.length == 12 || "Mobile phone is incorrect",
    ],

    showPassword: false,

    // password MySecret123
    password: "",
    passwordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "Can not be less than 8 characters",
      (v) => v.length <= 30 || "Can not be more than 30 characters",
    ],
    passwordHasError: false,
    passwordErrorMessage: "password",

    showConfirmPassword: false,

    // confirm password
    confirmPassword: "",
    confirmPasswordRules: [
      (v) => !!v || "Required",
      //   (v) => v === this.password || "The password confirmation does not match.",
    ],
    confirmPasswordHasError: false,
    confirmPasswordErrorMessage: "confirm password",
  }),

  computed: {},

  created() {
    let parameters = this.$route.query;

    if ("token" in parameters) {
      this.token = parameters.token;
    }
  },

  watch: {
    // phone1
    // phone1(val) {
    //   this.phone1 = this.$helpers.formatInputMobileNumber(val);
    // },

    // phone2
    // phone2(val) {
    //   this.phone2 = this.$helpers.formatInputMobileNumber(val);
    // },
  },

  methods: {
    // validate
    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.userAcceptInvite();
      }
    },

    // userAcceptInvite
    async userAcceptInvite() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `invites/user-accept-invite/${this.token}`,
        {
          token: this.token,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone1: this.phone1,
          phone2: this.phone2,
          password: this.password,
          confirm_password: this.confirmPassword,
        },
        "Accept Invite successful, you can now login"
      );

      if (res.status == 200) {
        // const data = res.data;

        // console.log(data);

        this.successfulUserAcceptInvite = true;

        this.$refs.form.reset();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-accept-invite {
  // min-height: 100vh;
  .content {
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
    }
  }
}
</style>
