<template>
  <div class="background py-16">
    <UserAcceptInvite />
  </div>
</template>

<script>
import { mapState } from "vuex";

import UserAcceptInvite from "@/components/elements/invites/user-accept-invite/UserAcceptInvite";

export default {
  name: "DocumentManagementUserAcceptInvite",

  components: {
    UserAcceptInvite,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (this.auth.loggedIn) {
      this.$router.push({ name: "DashboardInbox" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
